<template>
  <v-app id="app">
    <TemplateHeader></TemplateHeader>
    <v-main>
      <v-container fluid class="fill-height ma-0"
        v-if="this.$store.state.token != ''">
        <div class="d-flex align-center justify-center flex-shrink-1">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
    <TemplateFooter></TemplateFooter>
  </v-app>
</template>

<script>
import TemplateHeader from "./components/Template/Header.vue";
import TemplateFooter from "./components/Template/Footer.vue";

const cognitoURL = require("./cognito.js").endpoint;
const cognitoUserPoolClient = require("./cognitouserpoolclient.js").endpoint;
const cognitoCallBackURL = require("./cognitocallbackurl.js").endpoint;
const cognitoResponseType = "token";
const cognitoScopes = "email+openid+profile";
const permissions = require("./permissions.js");

export default {
  name: "App",
  data() {},
  components: {
    TemplateHeader,
    TemplateFooter,
  },
  methods: {
    makeLogin() {
      this.$store.commit("setToken", "");
      this.$store.commit("retrieveClients", true);
      this.$store.commit("retrieveResources", true);
      window.location.href =
        cognitoURL +
        "/authorize?identity_provider=Google&client_id=" +
        cognitoUserPoolClient +
        "&response_type=" +
        cognitoResponseType +
        "&scope=" +
        cognitoScopes +
        "&redirect_uri=" +
        cognitoCallBackURL;
    },
    parseJwt(token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    },
  },
  beforeCreate() {
    document.title = "Support Console";
    this.$store.commit("initialiseStore");
    // Se imprime aquí, por que no se usará aun
    console.log("Permisos: ", permissions);
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      // Store the state object as a JSON string
      sessionStorage.setItem("store", JSON.stringify(state));
      if (this.$store.state.token == null) {
        this.makeLogin();
      }
    });

    // Obtener access_token desde la url

    let queryString = window.location.href.split("#");
    if (queryString.length > 1) {
      const urlParams = new URLSearchParams(queryString[1]);
      this.$store.commit("setToken", urlParams.get("access_token"));
    } else if (this.$store.state.token == "") {
      this.$store.commit("setToken", null);
    }

    // Manejo de Permisos
    let tokenData = this.parseJwt(this.$store.state.token);
    let elementos = Object.keys(permissions);
    if (tokenData["cognito:groups"] != undefined) {
      elementos.forEach((element) => {
        this.$store.commit("setAppPermissions", {
          name: element,
          data: permissions[element].filter((x) =>
            tokenData["cognito:groups"].includes(x)
          ),
        });
      });
    }

    // console.log("queryString: ",queryString[0]);
    let route = new URL(queryString[0]);
    this.$router.push(route.pathname);
  },
};
</script>

<style>
.v-card,
.v-table {
  min-width: 30vw;
}
</style>
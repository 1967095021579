import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/',
    alias: '/listclients',
    name: 'ListClients',
    component: () => import('../components/Clients/ListClients.vue'),
  },
  {
    path: '/editclient',
    name: 'editClient',
    component: () => import('../components/Clients/EditClient.vue'),
  },
  {
    path: '/deleteclient',
    name: 'deleteClient',
    component: () => import('../components/Clients/DeleteClient.vue'),
  },
  {
    path: '/listresources',
    name: 'ListResources',
    component: () => import('../components/Resources/ListResources.vue'),
  },
  {
    path: '/editresource',
    name: 'EditResource',
    component: () => import('../components/Resources/EditResource.vue'),
  },
  {
    path: '/listactions',
    name: 'ListActions',
    component: () => import('../components/Actions/ListActions.vue'),
  },
  {
    path: '/execactions',
    name: 'ExecActions',
    component: () => import('../components/Actions/ExecActions.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../components/Template/Login.vue'),
  // },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../components/Template/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  //history: createWebHashHistory(),
  routes,
});

export default router;
import { createApp } from 'vue'
import App from './App.vue'
// Importando Vuex Next
import { store } from './plugins/store';
// Importando Router
import router from './plugins/router';
// Importando Vuetify
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

createApp(App)
  .use(vuetify)
  .use(store)
  .use(router)
  .mount('#app')

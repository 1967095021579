<template>
  <v-app-bar app bottom fixed width="100%" padless>
    <v-app-bar-title>Consola de Soporte</v-app-bar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: "TemplateHeader",
};
</script>

<style scoped>
/* #navbar-logo{
        height: auto;
        width: 15%;
    }

    #site-navbar{
        height: 6em;
        padding: 0 2em 0 2em;
    }

    nav ul li a{
        color: #616161;
        font-weight: 600;
    }

    #inicio-button{
      color: #616161;
      font-weight: 600;
      padding: 1rem 15px;
      height: 100%;
    } */
</style>
module.exports = {
    // Por Función:
    leerCliente: [ 'general', ],
    editarCliente: [ 'ti-systemadmins', ],
    borrarCliente: [ 'ti-systemadmins', ],
    crearCliente: [ 'ti-systemadmins', ],
    leerRecurso: [ 'general', ],
    editarRecurso: [ 'ti-systemadmins', ],
    leerAccion: ['general'],
    detalleEC2: [ 'general', ],
        
    // shellScriptEC2: [ 'general', ], // Permisos según Ambiente
    // statusEC2: [ 'general', ], // Permisos según Ambiente

    // Por tipo de Ambiente:
    Demo: ['general'],
    Desarrollo: ['ti-desarrollo'],
    Producción: ['ti-systemadmins'],
};
import axios from 'axios';
import { createStore } from 'vuex'
const api = require("../endpoint.js");

export const store = createStore({
  state() {
    return {
      // Listado de Regiones Incluidas.
      AWSRegions: ["us-east-1", "us-east-2", "us-west-1", "us-west-2",],
      // Listado de Recursos Soportados.
      AWSServicios: ["EC2", "RDS",],
      // Listado de Tipos de Ambientes.
      EnvironmentOptions: [ "Producción", "Desarrollo", "Demo",],
      // Permisos del usuario.
      AppPermissions: {},
      // Listado de Clientes.
      ListClients: [],
      retrieveClients: true,
      selectedClient: '',
      // Servicios de un cliente en especifico.
      retrieveResources: true,
      ListResources: [],
      selectedResource: '',
      ec2Detail: null,
      selectedAction: '',
      actionStatus: '',
      actionLogs: '',
      // Manejo de Token, Sesión y Grupos
      token: '',
      isAdmin: false,
    }
  },
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (sessionStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
        );
      }
    },
    setAppPermissions(state, datos){
      state.AppPermissions[datos.name] = datos.data;
    },
    setToken(state, token) {
      state.token = token;
    },
    isAdmin(state, isadmin) {
      state.isAdmin = isadmin;
    },
    retrieveClients(state, estado) {
      state.retrieveClients = estado;
    },
    retrieveResources(state, estado) {
      state.retrieveResources = estado;
    },
    updateListClients(state, clients) {
      state.ListClients = clients;
    },
    setSelectedClient(state, client) {
      state.selectedClient = client;
    },
    updateSelectedClient(state, datos) {
      state.selectedClient = datos;
      let pos = state.ListClients.map(val => val.nombreCliente).indexOf(datos.nombreCliente);
      if (pos < 0) {
        state.ListClients.push(datos);
      }
    },
    deleteSelectedClient(state, datos) {
      state.selectedClient = datos;
      let pos = state.ListClients.map(val => val.nombreCliente).indexOf(datos.nombreCliente);
      if (pos > 0) {
        state.ListClients.splice(pos, 1);
      }
    },
    updateEC2Detail(state, datos) {
      state.ec2Detail = datos;
    },
    clearResources(state) {
      state.ListResources = {};
      Array.prototype.forEach.call(state.AWSRegions, region => {
        state.ListResources[region] = {};
        //state.ListResources[region] = { servicios: "Recuperando Servicios", };
        Array.prototype.forEach.call(state.AWSServicios, servicio => {
          state.ListResources[region][servicio] = [{ mensaje: 'Recuperando recursos ...' }];
        });
      });
      state.retrieveResources = true;
    },
    updateListResources(state, datos) {
      state.ListResources[datos.region][datos.servicio] = datos.recursos;
    },
    setSelectedResource(state, recurso) {
      state.selectedResource = recurso;
    },
    updateSelectedResource(state, datos) {
      state.selectedResource.recurso = {
        ...state.selectedResource.recurso,
        ...datos
      };

      let pos = state.ListResources[state.selectedResource.region][state.selectedResource.servicio].map(val => val.ResourceId).indexOf(state.selectedResource.recurso.ResourceId);
      state.ListResources[state.selectedResource.region][state.selectedResource.servicio][pos] = { 
        ...state.ListResources[state.selectedResource.region][state.selectedResource.servicio][pos],
        ...state.selectedResource.recurso
      };
    },
    setSelectedAction(state, action) {
      state.selectedAction = action;
    },
    setActionStatus(state, status) {
      state.actionStatus = status;
    },
    setActionLogs(state, logs) {
      state.actionLogs = logs;
    },
  },
  actions: {
    getClients: async (context) => {
      let endp = api.endpoint + '/clientes/';
      const config = {
        headers: { Authorization: `Bearer ${context.state.token}` }
      };
      await axios.get(endp, config)
        .then((response) => {
          context.commit('updateListClients', response.data);
          context.commit('retrieveClients', false);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            context.commit('setToken', null);
          }
          console.log("Error: ", error);
        });
    },
    getResources: async (context) => {
      context.commit('clearResources');
      const config = {
        headers: { Authorization: `Bearer ${context.state.token}` }
      };
      await Promise.all(context.state.AWSRegions.map(async (region) => {
        await Promise.all(context.state.AWSServicios.map(async (servicio) => {
          let endp = api.endpoint + '/clientes/' + context.state.selectedClient.nombreCliente + '/' + region + '/' + servicio + '/recursos/';
          await axios.get(endp, config)
            .then((response) => {
              context.commit('updateListResources', { region: region, servicio: servicio, recursos: response.data });
              context.commit('retrieveResources', false)
            })
            .catch((error) => {
              if (error.response.status == 401) {
                context.commit('setToken', null);
              } else {
                context.commit('updateListResources', { region: region, servicio: servicio, recursos: error.response.data });
              }
              console.log("Error: ", error);
            });
        }));
      }));
    },
    executeAction: async (context) => {
      let endp =
        api.endpoint +
        "/clientes/" +
        context.state.selectedClient.nombreCliente +
        "/" +
        context.state.selectedResource.region +
        "/EC2/recursos/" +
        context.state.selectedResource.recurso.ResourceId +
        "/accion/shellscript";
      const config = {
        headers: { Authorization: `Bearer ${context.state.token}` },
      };
      let params = {
        Action: "EXECUTE",
        Command: context.state.selectedAction,
      };
      await axios
        .post(endp, params, config)
        .then((response) => {
          context.commit("setActionStatus", {
            CommandId: response.data.Command.CommandId,
            Status: response.data.Command.Status,
          })
        })
        .catch((error) => {
          if (error.response.status == 401) {
            context.commit("setToken", null);
          }
          console.log("Error: ", error);
        });

      let checkStatus = setInterval(async () => {
        // Rescata Status
        params = {
          Action: "STATUS",
          CommandId: context.state.actionStatus.CommandId
        }
        await axios
          .post(endp, params, config)
          .then((response) => {
            context.commit("setActionStatus", {
              CommandId: response.data.Commands[0].CommandId,
              Status: response.data.Commands[0].Status,
            });
          })
          .catch((error) => {
            if (error.response.status == 401) {
              context.commit("setToken", null);
            }
            console.log("Error: ", error);
          });
        
        // Rescata Logs
        params = {
          Action: "LOGS",
          CommandId: context.state.actionStatus.CommandId
        }
        await axios
          .post(endp, params, config)
          .then((response) => {
            context.commit("setActionLogs", response.data.events);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              context.commit("setToken", null);
            }
            console.log("Error: ", error);
          });

        if (context.state.actionStatus.Status == 'Success' || context.state.actionStatus.Status == 'Failed') clearInterval(checkStatus)
      }, 3000);
    },
  },
});
<template>
  <v-footer density="comfortable" app bottom fixed width="100%" padless>
    <p>Desarrollado por </p>
    <v-img src="../../assets/s4e.png" max-width="80" max-height="60" pa-auto ma-auto></v-img>
  </v-footer>
</template>

<script>
export default {
  name: "TemplateFooter",
};
</script>

<style scoped>

</style>
